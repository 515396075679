const translations = {
    title: {
        titleTrack:'Track',
        titleBetaVersion:"Beta Version",
        titleCustomsDutiesCaculator:'Customs Duties Calculator',
        titleLandShipping:'Land Shipping',
        titleSeaShipping:'Sea Shipping',
        titleAirFright:'Air Freight',
        titleCustomsTariffAndCondition:'Customs Tariff',
        titleMainPage:'Instant booking, management and tracking of shipment in one place',
        mainpage:'main page',
        subtitlemain:'Get instant quotes and book your shipment in seconds',
        subtitlemainMobile:'Book your shipment to and from anywhere in the world',
        seapage:'Ocean Freight',
        subtitlesea:'Comprehensive logistics solutions for sea freight of all types of goods, in collaboration with major shipping lines. We offer competitive rates and a straightforward booking process to select the right container for your shipment.',
		landpage:'Road Freight',
        subtitleland:'Reliable and comprehensive international transportation services to and from neighboring countries. We ensure professionalism and attention to every stage of the shipping process, guaranteeing safe and efficient transport of your goods.',
		airpage:'Air Freight',
        subtitleair:'Extensive coverage and innovative solutions tailored to meet the needs of clients with varied and unique shipments. We partner with the most trusted air freight companies worldwide.',
        customsclearance:'Customs Clearance',
        subtitlcustomsclearance:'Efficient and high-quality customs clearance services. Our team consistently reviews and updates customs regulations and ensures their accuracy, enabling smooth and error-free clearance of your goods.',


    },
    labelTrackPage:{
        lastLocation:'Last Location',
        estimatedDate:'Estimated Arrive Date ',
        forgetNumber:'Did you forget the shipment number?',
        changeContainer:'The container is path has been changed.',
        placeholderSearch:'Enter the shipment number',
        trackingDeatils:'Tracking Details',
        billDeatils:'Details',
        theContainer:'Container',
        tableBill0:'Vessel',
        tableBill2:'Containers No',
        tableBill3:'Packages No',
        tableBill4:'Gross Weight',
        tableBill1:'Goods Type',


        containersDetails:'Containers Details',
        containersTable:'Containers No',
        containerNumber:'Container Number',

        tableContainer0:'Goods Type',
        tableContainer1:'Packages No',
        tableContainer2:'Gross Weight',

    },
    labelContactUs:{
        ContactUs:'Stay in touch with the Across Mena team and do not hesitate to call anytime you want to ask your questions and inquiries and we will be happy to listen to you and provide the solution that suits your needs.',
        address:'Address',
        building:'Syria, Latakia, American Street, Elias Awad Building',
        landLine:'Phone',
        phone:'Phone',
        email:'Email',
    },
    labelAuth:{
		infoRegister:'make your booking experience excellent',
		why:'Why',
		google:'Google',
		facebook:'facebook',
        signOut:' Sign Out',
        signIn:'Sign In',
        welcome:'Welcome!',
        password:'Password',
        forgetPassword:'Forget Password',
        noAccount:'Do not have an account yet?',
        emailOrPhone:'Email/Phone',
        subText:'Welcome to Across-Mena',

        signUp:'Sign Up',
        descriptionSignUp:'make your booking experience excellent',
        firstName:'First Name',
        lastName:'Last Name',
        Email:'Email',
        CompanyName:'Company Name',
        Country:'Country',
        PhoneNumber:'Phone Number',
        confirmPassword:'Confirm Password',
        terms:'By creating an account, you agree to our Terms & Conditions',
        createAccount:'Create Account',
        backTo:'Back To?',


    },
    labelHomePage:{
		noSelection:'no Selection',
		recentSelection:'Recent Selections :',
        fromMena:'To',
        toMena:'From',
        questionContact:'How would you like us to contact you ?',
        headerRequet:'we do not have an immediate quote for this route , you can request a quote and we will get back to you with competitive prices to meet your request.',
        ourservice:'Our Services',
        ourtools:'Our Tools',
        headermain:'We provide flexible and advanced logistics services to transport goods to and from any location worldwide, ensuring the most time- and cost-efficient solutions.We are continuously innovating and enhancing our services, leveraging the latest technological advancements to meet your diverse logistics needs with ease and professionalism.',
		accoudiontext:'We push promotion and improvement to include all countries until we reach the gateway to foreign trad',
        accordiontitle:'Customs tariffs and conditions',
        accordionsubtitle:'Effective trade compliance to protect yourself from foreign trade risks',
        accordionsubP_1:'Ease of searching and classifying products',
        accordionsubP_2:'Knowledge of import and export conditions',
        accordionsubP_3:'Effective commercial compliance with customs requirements and procedures',
        accordionsubP_4:'Calculate customs duties and taxes imposed on import goods',
        accordionsubP_5:'Knowledge of prohibited materials and permitted non-tariff measures',
		labelheaderTrackShipment:'Shipment Tracking',
		text1TrackShipment:'Our advanced tracking system provides an accurate and seamless experience, allowing you to monitor your shipments in real-time.',
		text2TrackShipment:'Through this system, you can: Access the real-time location of your shipment at any moment,Enhance your operational planning and avoid unexpected delays,Enjoy greater transparency and complete control, ensuring your shipping process is safer and more efficient.',
        labelheaderAppointemnt:'Instant Booking',
        text1Appointement:'There is no need to delve into the complexities of shipping lines and their requirements. Without experience, you will make mistakes that could be devastating to some.',
        text2Appointement:'Book your shipment yourself through an easy and simple interface and get quotes in complete transparency and in real time',

        ourtoolstext:
        'We support our customers with many valuable free tools to guide them and help them make informed decisions that enable them to efficiently plan their business operations',
    },
    labelFooter:{
        labelCompany:'Company',
        labelSelectCustom:'Customs Duties Calculator'

    },
    labelNavbar:{

        home:'Home',
        services:'Services',
        landShipping:'Land Shipping',
        seaShipping:'Sea Shipping',
        airFreight:'Air Freight',
        customClearance:'Custom Clearance',
        tools:'Tools',
        customsTariffAndConditions:'Customs Tariff',
        customsDutiesCalculator:' Customs Duty Calculator',
        shippingCalculators:'Shipping Calculators',
        containerTypesAndSizes:'Container Types',
        truckTypesAndSizes:'Truck Types',
        incoterms:'Incoterms',
        internationalTradeTerms: 'Trade Terms',
		containerTypesAndSizes2:'Types and sizes of containers',

        about:'About Us',
        whoWeAre:'Who We Are',
        privacyPolicy:'Privacy Policy',
        help:'Help',
        consulting:'Consulting',
        contact:'Contact Us',
        track:'Track',
        language:'Ar',

        item1:'Understand the duties applicable to your goods before initiating the shipping process.',
		item2:'Make sure your goods are properly classified and familiarize yourself with the import and export conditions',
        item3:'Avoid additional fees on the weight of your shipment using the weight calculator',
        item4:'Learn about truck sizes to facilitate the process of choosing the right truck',
        item5:'Learn about container sizes to facilitate the process of choosing the right truck',
        item6:'Determine the responsibility of both the exporter and importer and distribute tasks, costs and risks',

    },
    labelDutiesCalculator: {
        fillOut:'Please fill out the fields',
        errorAgremnt:'This field is required',
        RsmZra:'Atomic energy fees',
        TotalTrabTax:'Total taxes and fees',
        BdlImport:'Service fee for granting import license',
        KramaNoL:'Penalty for not having consular visa',
        RsmTras:'Import license fee',

        RsmNoKnasolet:'Fee for not having consular visa',

        ischoosen:'The item is chosen',
        origin:'Origin',
        source:'Source',
        weight:'Weight',
        unitTitleNumber:'Number',
        unitTitleWatt:'Electrical Power',
        unitTitleSize:'Size',
        unitTitleCapacity:'Capacity',
        depends:'depends',

        unitWeight:'KG',
        unitWeight2:'carat',
        unitWatt:'1000 kWh',
        unitNumber:'number',
        unitNumber2:'number of pairs',
        unitSize:'meter',
        unitSize2:'m²',
        unitSize3:'m³',
        unitCapacity:'liter',

        resultsSearch:'No matching results',
        waitingLoad:'Please wait...',
        noImport:'This item is prohibited from import',
        noOptions:'No options',

        browseTariffs:'tariff browser',
        customsitem:'Customs item',
        item:'Item',

        lycraLabel:'Does it contain lycra?',
        coloredLabel:'Are the threads coloured?',
        brandLabel:'Are the goods an international brand?',
        tubesLabel:'Does the pipe measure less than or equal to 3 inches?',
        industrialLabel:'Is it of industrial origin?',
        elementaryLabel:'Is matter elementary?',

        priceTotalLabel:'The total price',
        noteMinimumPrice:'Note: Minimum indicative price per unit',//Note: The minimum indicative price is one
        noteMustMinimun:'The total value of the entry must be a minimum',//The price of customs  must be a minimum
        noteDependsonWeight:'Depending on the entered weight, the total price must be a minimum',//Depending on the entered weight, the total value must be a minimum

        noteItemAndOrigin:'This item + origin has already been selected',//This item + origin has already been selected
        noteItemAndOrigin2:'Please choose another item or origin',//Please choose another item or origin
        totalValue:'Total Value',
        conversionRate:'Conversion Rate',
        priceUnitSyrian:'SY',
        totalValueSyrian:'Total Value Syrian',
        valueGoodsWithInsurance:'Value Goods With Insurance',
        certifiedConsulate:'Is the invoice certified by the consulate?',
        agreeTermsConditions:'Agree to terms and conditions',
        calculateCustomsFees:'Calculate Customs Fees',
        calculateFeesAndTaxes:'Fees And Taxes',
        customsFees:'Customs Fees',
        consumptionFee:'Consumption Fee',
        // localConstruction:'محلية عمران',
        localGovernoratefee:'Local Governorate Fee',
        supportAndDevelopmentOfLocalProductionFee:'Support And Development Of Local Production Fee',
        rehabilitationAndProtectionOfCitiesAndFacilitiesFee:'Rehabilitation And Protection Of Cities And Facilities Fee',
        droughtAndNaturalDisasterFundFee:'Drought And Natural Disaster Fund Fee',
        advanceOnIncomeTaxFee:'Advance On Income Tax Fee',
        totalTaxes:'Total Taxes',

        stampsAndValueAddedTaxes:'Stamps And Value Added Taxes',
        mandatoryInsuranceFee:'Mandatory Insurance Fee',
        stampFee:'Stamp Fee',
        localGovernorateTax:'Local Governorate Tax',
        advanceOnIncomeTax:'Advance On Income Tax',
        nationalContributionFeeForReconstruction:'National Contribution Fee For Reconstruction',
        totalTaxes2:'total Taxes2',
        disClaimer:'Disclaimer: The contents of this website do not constitute legal or professional advice and carry no legal force. The purpose of the website is to enhance public access to information regarding duty rates and other compliance requirements for customs clearance of goods. While every effort is made to ensure correct, complete and up-to-date information.'

    },
    labelProhibitedPage:{
        titleFormFeedBack:'We are dedicated to offering a great experience for our customers, and we see your feedback as a key motivator for our ongoing growth and improvement',
        chooseItem:'The HS Code was selected',

        itemNumber:'Item Number',
        nameContent:'Content Title',
        problemFeedBack:'Problem Type',
        typeFeedBack:'Section Type',
        messageFeedBack:'Message',
        Choose:'Select..',

        lackinformation:'Lack of information',
        otherwise:"Otherwise",




        warning1:'Disclaimer: These tariff tables are included from the Customs Tariff List of the Syrian Arab Republic and are provided here as a service and general reference.',
        warning2:'Every effort has been made to ensure that the information provided is complete and accurate. However, the actual tariff classification, evaluation and updating of the nomenclature is left to the customs authorities in the Syrian Arab Republic.',
        notnotes:'There No Notes',
        placeholderSearch:'Search by description or code',
        noResults:'No results',
		Result:'Result',
        commercialDescription:'Commercial Description',
        importConditions:'Import Conditions',
        exportConditions:'Export Conditions',
        feeCalculation:'Fee Calculation',
        share:'Share',
        copyItemLink:'Copy The Item Link',
        customsFees:'Customs Fees',
        importRestrictions:'Import Restrictions',
        importProhibited: 'Import Prohibited',
        importAllowed: 'Import Allowed',
        indicativePrice:'Indicative Price',
        importConditionsLabel:'Import Conditions Label',
        exportRestrictions:'Export Restrictions',
        exportProhibited:'Export Prohibited',
        exportAllowed:'Export Allowed',
        agriculturalQuarantine:'Agricultural Quarantine',
        finance:'Finance',
        needsfinancing:'Needs Financing',
        financingTerm:'Financing Term',
        minimumUnitValue:'Minimum Unit Value',
        nothing:'Nothing',

    },

    labelServices:{
		noResultTitle:'Sorry no results found',
		noResultsubTitle:'Please leave your contact information and our team will contact you to assist you.',
        message:'Message',
        placeholderMessgae:'Enter Message',
        placeholderWhatsapp:'WhatsApp',
        container1:'20 ft',
        container2:'40 ft',
        container3:'40feet_freeser',
        container4:'40 HC',

        textVolumWeight:'Fees are calculated based on the volumetric weight because the volumetric weight of the packages is greater than the actual weight',
        textActualWeight:'Fees are calculated on the actual weight because the actual weight of the packages is greater than the volumetric weight',
        AllKindsGoods:'All kinds of goods',
        InvalidEmail:'Invalid email',
        InvalidPhone:'Invalid phone number',
        warningLabel:'Please fill out the required fields',
        goodsLoaded:'Place of Loading',
        placeholderGoodsLand:'Search by cities',
        goodsDischarge:'Place of Discharge',
        totalGoodsWeight:'Total Goods Weight',
        placeholderEnterTotalGoodsWeight:'Enter Total Goods Weight',
        keloGram:'Kg',
        ton:'Ton',
        actualTimeOfArrival:'Departure Date',
        placeholderSelectDate:'Select Date',
        goodsType:'Goods Type',
        placeholderselectGoodsType:'Select Goods Type',
        goodsDescription:'Goods Description',
        placeholderEnterDetailedGoodsDescription:'Enter Detailed Goods Description',
        truckType:'Truck Type',
        truckFlat:'Flat',
        truckFreezer:'Freezer',
        truckLocomotiveAndTrailer:'Locomotive And Trailer',
        truckTipper:'Tipper',
        truckClosedContainer:'Closed Container',
        name:'Name',
		phone:'Phone',
        placeholderName:'Enter Name',
        company:'Company Name',
        placeholderCompany:'Enter Company Name (Optional)',
        numberPhone:'Phone',
        placeholderNumberPhone:'Enter Phone Number',
        email:'Email',
        placeholderEmail:'Enter Email',

        movementType:'Movement Type',
        placeholderMovementType:'Enter Movement Type',
		loading:'Loading',
		discharge:'Discharge',
        loadingPort:'Loading',
        placeholderPort:'Search by country or port',
        dischargePort:'Discharge',

        containerType:'Container Type',
        placeholderContainerType:'Select Container Type',
        maximumContainerLoad:'Fits up to',

        loadingAirport:'Airport of Loading',
        placeholderAirPort:'Search by country or airport',
        dischargeAirPort:'Airport of Discharge',

        fromDoor:'from door',
        fromPort:'from port',
        fromAirPort:'from airport',

        toDoor:'to door',
        toPort:'to port',
        toAirPort:'to airport',

        packagesNumber :'Packages Number',
        placeholderEnterPackagesNumber:'Enter Packages Number',

        packagesLength:'Packages Length',
        placeholderEnterPackagesLength:'Enter Packages Length',
        packagesWidth:'Packages Width',
        placeholderEnterPackagesWidth:'Enter Packages Width',
        packagesHeight:'Packages Height',
        placeholderEnterPackagesHeight:'Enter Packages Height',

        placeholderUnitPackages:'cm',

        actualWeight:'Actual Weight',
        weightUnit:'KG',
        volumetricWeight:'Volumetric Weight',
    },
    sectionsAccordion:{
        label21:'All kinds of goods',
        label1: "LIVE ANIMALS; ANIMAL PRODUCTS",
        label2: "VEGETABLE PRODUCTS",
        label3: "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR CLEAVAGE PRODUCTS; PREPARED EDIBLE FATS; ANIMAL OR VEGETABLE WAXES",
        label4: "PREPARED FOODSTUFFS; BEVERAGES, SPIRITS AND VINEGAR; TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES",
        label5: "MINERAL PRODUCTS",
        label6: "PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES",
        label7: "PLASTICS AND ARTICLES THEREOF; RUBBER AND ARTICLES THEREOF",
        label8: "RAW HIDES AND SKINS, LEATHER, FURSKINS AND ARTICLES THEREOF; SADDLERY AND HARNESS; TRAVEL GOODS, HANDBAGS AND SIMILAR CONTAINERS; ARTICLES OF ANIMAL GUT (OTHER THAN SILK-WORM GUT)",
        label9: "WOOD AND ARTICLES OF WOOD;  WOOD CHARCOAL;  CORK AND ARTICLES OF CORK;  MANUFACTURES OF STRAW, OF ESPARTO OR OF OTHER PLAITING MATERIALS; BASKETWARE AND WICKERWORK",
        label10: "PULP OF WOOD OR OF OTHER FIBROUS CELLULOSIC MATERIAL; RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD;PAPER AND PAPERBOARD AND ARTICLES THEREOF",
        label11: "TEXTILES AND TEXTILE ARTICLES",
        label12: "FOOTWEAR, HEADGEAR, UMBRELLAS, SUN UMBRELLAS, WALKING-STICKS, SEAT-STICKS, WHIPS, RIDING-CROPS AND PARTS THEREOF; PREPARED FEATHERS AND ARTICLES MADE THEREWITH; ARTIFICIAL FLOWERS; ARTICLES OF HUMAN HAIR",
        label13: "ARTICLES OF STONE, PLASTER, CEMENT, ASBESTOS, MICA OR SIMILAR MATERIALS; GLASS AND GLASSWARE",
        label14: "NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES, PRECIOUS METALS, METALS CLAD WITH PRECIOUS METAL,AND ARTICLES THEREOF; IMITATION JEWELLERY; COIN",
        label15: "BASE METALS AND ARTICLES OF BASE METAL",
        label16: "MACHINERY AND MECHANICAL APPLIANCES; ELECTRICAL EQUIPMENT; PARTS THEREOF; SOUND RECORDERS AND REPRODUCERS, TELEVISION IMAGE AND SOUND RECORDERS AND REPRODUCERS, AND PARTS AND ACCESSORIES OF SUCH ARTICLES",
        label17: "VEHICLES,  AlRCRAFT,  VESSELS AND ASSOCIATED TRANSPORT EQUIPMENT",
        label18: "OPTICAL, PHOTOGRAPHIC, CINEMATOGRAPHIC, MEASURING, CHECKING, PRECISION, MEDICAL OR SURGICAL INSTRUMENTS AND APPARATUS; CLOCKS AND WATCHES; MUSICAL INSTRUMENTS; PARTS AND ACCESSORIES THEREOF",
        label20: "MISC LLANEOUS MANUFACTURED ARTICLES",
    },
    chaptersAccordion:{
        label1: "Live animals",
        label2: "Meat and edible meat offal",
        label3: "Fish and crustaceans, molluscs and other aquatic invertebrates",
        label4: "Dairy produce; birds eggs; natural honey; edible products of animal origin, not elsewhere specified or included",
        label5: "Products of animal origin, not elsewhere specified or included",
        label6: "Live trees and other plants; bulbs, roots and the like; cut flowers and ornamental foliage",
        label7: "Edible vegetables and certain roots and tubers",
        label8: "Edible fruit and nuts; peel of citrus fruit or melons",
        label9: "Coffee, tea, maté and spices",
        label10: "Cereals",
        label11: "Products of the milling industry; malt; starches; inulin; wheat gluten",
        label12: "Oil seeds and oleaginous fruits; miscellaneous grains, seeds and fruit; industrial or medicinal plants; straw and fodder",
        label13: "Lac; gums, resins and other vegetable saps and extracts",
        label14: "Vegetable plaiting materials; vegetable products not elsewhere specified or included",
        label15: "Animal, vegetable or microbial fats and oils and their cleavage products; prepared edible fats; animal or vegetable waxes.",
        label16: "Preparations of meat, of fish, of crustaceans, molluscs or other aquatic invertebrates, or of insects",
        label17: "Sugars and sugar confectionery",
        label18: "Cocoa and cocoa preparations",
        label19: "Preparations of cereals, flour, starch or milk; pastrycooks products",
        label20: "Preparations of vegetables, fruit, nuts or other parts of plants",
        label21: "Miscellaneous edible preparations",
        label22: "Beverages, spirits and vinegar",
        label23: "Residues and waste from the food industries; prepared animal fodder",
        label24: "Tobacco and manufactured tobacco substitutes; products, whether or not containing nicotine, intended for inhalation without combustion; other nicotine containing products intended for the intake of nicotine into the human body",
        label25: "Salt; sulphur; earths and stone; plastering materials, lime and cement",
        label26: "Ores, slag and ash",
        label27: "Mineral fuels, mineral oils and products of their distillation; bituminous substances; mineral waxes",
        label28: "Inorganic chemicals; organic or inorganic compounds of precious metals, of rare-earth metals, of radioactive elements or of isotopes",
        label29: "Organic chemicals",
        label30: "Pharmaceutical products",
        label31: "Fertilisers",
        label32: "Tanning or dyeing extracts; tannins and their derivatives; dyes, pigments and other colouring matter; paints and varnishes; putty and other mastics; inks",
        label33: "Essential oils and resinoids; perfumery, cosmetic or toilet preparations",
        label34: "Soap, organic surface-active agents, washing preparations, lubricating preparations, artificial waxes, prepared waxes, polishing or scouring preparations, candles and similar articles, modelling pastes, «dental waxes» and dental preparations with a basis of plaster",
        label35: "Albuminoidal substances; modified starches; glues; enzymes",
        label36: "Explosives; pyrotechnic products; matches; pyrophoric alloys; certain combustible preparations",
        label37: "Photographic or cinematographic goods",
        label38: "Miscellaneous chemical products",
        label39: "Plastics and articles thereof",
        label40: "Rubber and articles thereof",
        label41: "Raw hides and skins (other than furskins) and leather",
        label42: "Articles of leather; saddlery and harness; travel goods, handbags and similar containers; articles of animal gut (other than silk-worm gut)",
        label43: "Furskins and artificial fur; manufactures thereof",
        label44: "Wood and articles of wood; wood charcoal",
        label45: "Cork and articles of cork",
        label46: "Manufactures of straw, of esparto or of other plaiting materials; basketware and wickerwork",
        label47: "Pulp of wood or of other fibrous cellulosic material; recovered (waste and scrap) paper or paperboard",
        label48: "Paper and paperboard; articles of paper pulp, of paper or of paperboard",
        label49: "Printed books, newspapers, pictures and other products of the printing industry; manuscripts, typescripts and plans",
        label50: "Silk",
        label51: "Wool, fine or coarse animal hair; horsehair yarn and woven fabric",
        label52: "Cotton",
        label53: "Other vegetable textile fibres; paper yarn and woven fabrics of paper yarn",
        label54: "Man-made filaments; strip and the like of man-made textile materials",
        label55: "Man-made staple fibres",
        label56: "Wadding, felt and nonwovens; special yarns; twine, cordage, ropes and cables and articles thereof",
        label57: "Carpets and other textile floor coverings",
        label58: "Special woven fabrics; tufted textile fabrics; lace; tapestries; trimmings; embroidery",
        label59: "Impregnated, coated, covered or laminated textile fabrics; textile articles of a kind suitable for industrial use",
        label60: "Knitted or crocheted fabrics",
        label61: "Articles of apparel and clothing accessories, knitted or crocheted",
        label62: "Articles of apparel and clothing accessories, not knitted or crocheted",
        label63: "Other made up textile articles; sets; worn clothing and worn textile articles; rags",
        label64: "Footwear, gaiters and the like; parts of such articles",
        label65: "Headgear and parts thereof",
        label66: "Umbrellas, sun umbrellas, walking-sticks, seat-sticks, whips, riding-crops and parts thereof",
        label67: "Prepared feathers and down and articles made of feathers or of down; artificial flowers; articles of human hair",
        label68: "Articles of stone, plaster, cement, asbestos, mica or similar materials",
        label69: "Ceramic products",
        label70: "Glass and glassware",
        label71: "Natural or cultured pearls, precious or semi-precious stones, precious metals, metals clad with precious metal, and articles thereof; imitation jewellery; coin",
        label72: "Iron and steel",
        label73: "Articles of iron or steel",
        label74: "Copper and articles thereof",
        label75: "Nickel and articles thereof",
        label76: "Aluminium and articles thereof",
        label78: "Lead and articles thereof",
        label79: "Zinc and articles thereof",
        label80: "Tin and articles thereof",
        label81: "Other base metals; cermets; articles thereof",
        label82: "Tools, implements, cutlery, spoons and forks, of base metal; parts thereof of base metal",
        label83: "Miscellaneous articles of base metal",
        label84: "Nuclear reactors, boilers, machinery and mechanical appliances; parts thereof",
        label85: "Electrical machinery and equipment and parts thereof; sound recorders and reproducers, television image and sound recorders and reproducers, and parts and accessories of such articles",
        label86: "Railway or tramway locomotives, rolling-stock and parts thereof; railway or tramway track fixtures and fittings and parts thereof; mechanical (including electro-mechanical) traffic signalling equipment of all kinds",
        label87: "Vehicles other than railway or tramway rolling-stock, and parts and accessories thereof",
        label88: "Aircraft, spacecraft, and parts thereof",
        label89: "Ships, boats and floating structures",
        label90: "Optical, photographic, cinematographic, measuring, checking, precision, medical or surgical instruments and apparatus; parts and accessories thereof",
        label91: "Clocks and watches and parts thereof",
        label92: "Musical instruments; parts and accessories of such articles",
        label93: "Arms and ammunition; parts and accessories thereof",
        label94: "Furniture; bedding, mattresses, mattress supports, cushions and similar stuffed furnishings; luminaires and lighting fittings, not elsewhere specified or included; illuminated signs, illuminated name-plates and the like; prefabricated buildings",
        label95: "Toys, games and sports requisites; parts and accessories thereof",
        label96: "Miscellaneous manufactured articles",
        label97: "Works of art, collectors pieces and antiques",
    },
    agreement:{
        title:'By using this tool, you agree to the following terms and conditions:',
        item_1:'This tool provides rough estimates of customs duties and taxes related to imported products, and is based on the information provided by the user. These estimates cannot be relied upon 100% due to changes in customs and tax laws and regulations.',
        item_2:'This tool is used entirely at the users risk, and the user bears full responsibility for the use of the tool and its results.',
        item_3:'Users should check applicable customs and tax laws and regulations in their country, and obtain legal advice if necessary, before deciding on a purchase.',
        item_4:'We are not responsible for any losses or damage incurred by the user as a result of using this tool.',
        item_5:'Users must provide accurate and correct information about the value, weight and origin of imported products, and we are not responsible for any inaccurate or missing information.',
        item_6:'We reserve the right to amend these terms and conditions from time to time, and users will be notified of any changes to these terms and conditions.',
        item_7:'This tool is provided "as is" without any warranties, either express or implied, including warranties of fitness for a particular purpose or non-infringement of intellectual property rights or laws and regulations of the users country.',
        item_8:'The use of this tool is subject to the current laws and regulations in the users country, and in the event of any dispute it must be resolved amicably and if necessary it must be submitted to the competent judiciary.',
        item_9:'Users must not use this tool in a way that violates intellectual property rights or current laws and regulations in the users country.',
    },


    bookingTitles:{
		agree:'I agree to the',
		help:'Request',
        labelDirct:'direct',
		labelCommodity:'Search by description or customs item',
		commodity:'commodity',
        labelTransshipment:'Station',
        labelNotChecks:'You must choose at least one option',
        unit:'KG',
		priceUnit:'USD',
        container:'CONTAINER',
        placeholderPortFrom:'Loading Port',
        placeholderPortTo:'Discharge Port',


        placeholderPortContainer:'container type',
		placeholderCargoWeight:'Enter Cargo Weight',
		placeholderHaulageReference:'Enter Haulage Reference',
        reqManager:'Request a manager for this shipment',
		howContact:'Let us help you enter data and upload shipping instructions on your behalf',
        labelPortOrigin:'Port of Origin',
        labelPortDistanition:'Port of Distanition',
        labelOriginFright:'Origin Fright',

        labelWhatShipping:'What are you shipping?',
        labelCheckBox:'My cargo is not temperature-controlied and does not include any hazardous or personal goods.',

        labelDetailsCargoCard:'What are the details of your cargo?',
        labelCargoDescription:'Cargo description',
        placeholderCargoDescription:'Describe the goods you',
        labelNumberPackage:'Number of package',
        labelReferenceNumber:'Reference number (optional)',
		HaulageReference:'Haulage reference(optional)',
		labelCargoWeight:'Cargo weight',
        unitCargoWeight:'KG',

        labelCardAddBook:'Export - Port to port',
        labelDeparture:'Departure',
        labelCargo:'Cargo',
        labelContainer:'Container',
        labelTotalPrice:'Total Price',

        labelEmail:'Email',
        labelYourEmail:'Your Email',
        labelCardEmail:'Please enter your email to contact you?',

        titleDetailsCard1:'Shipment Details',
        labelDirection:'Direction',
        labelShippingService:'Shipping service',
        labelExport:'export',
        labelPortToPort:'Port To Port',
        labelTradelane:'Trade lane',
        labelTransitTime:'Transit time',
        labelDays:'days',

        titleDetailsCard2:'Shipment & Service Charges',
        labelToBePaid:'To be paid by you',
        labelOriginServices:'Origin services',
        labelOceanServices:'Ocean services',
        labelDestinationServices:'Destination services',

        titleDetailsCard3:'Cargo details',
        labelContainerDetails:'Containers details',

        titleDetailsCard5:'Your details',
        labelCompany:'Company',
		bookingPart:'BookingPart',
        labelContactPerson:'Contact person',

        labelInfoContact1:'ACROSS MENA L.L.C ELIAS',
        labelInfoContact2:'ELIAS AWAD BILD- ALMUTANABBI ST- ALAMERCAN',
        labelInfoContact3:'LATTAKIA -SYRIA',

        labelAgreement:'I agree to the terms of service',

    },

 termServices:{
termOfServices:'Terms of Service',
defintions:'Definitions:',
title0:'These terms govern all bookings made between the company and the user, and we reserve the right to modify or cancel some or all of these terms at any time without prior notice.',
title1:'First: Destination of Goods',
title2:'Second: Shipment Cancellation',
title3:'Third: Modifications and Changes',
title4:'Fourth: Claims',
title5:'Fifth: Scope of Services',
title6:'Sixth: Fees and Charges',
title7:'Seventh: Shipment Inspections',
title8:'Eighth: Liability for Goods',
title9:'Ninth: Invoicing',
title10:'Tenth: Confidentiality',
p0:'Company: "We," Across MENA L.L.C., or any entity, group, or electronic Application affiliated with it.Platform: The company’s electronic domain at www.acrossmena.com.User: "You" or "Shipper" - any individual agreeing to the platform\'s Terms of Service and Privacy Policy or deals with the company, whether a natural or legal person.Laws, Legislation, Regulations, and Policies: The set of controls governing customs clearance and international trade in the Syrian Arab Republic, encompassing any Syrian law or international treaty recognized in Syria, provided it applies to situations that may be encountered by sector workers.',
p1:'If there is an incorrect address for the recipient, Across MENA will contact the shipper for notification. The shipment will be returned to its origin at the shipper\'s expense if the shipper does not rectify the issue within 48 hours of notification.Should the destination change to another address, redelivery fees will be assessed and added according to the new delivery rates.',
p2:'If the user wishes to cancel a booked shipment, they will bear the associated charges, which vary by container and carrier.A shipment may also be canceled by the carrier for various reasons, including the carrier’s policies towards certain countries or international decisions in this regard, or any foreign or force majeure event such as military operations at sea or on land. Across MENA bears no responsibility for this matter, and the shipper alone remains obligated to cover any costs or fees resulting from the shipment cancellation.Additionally, if the information provided to us about the shipment differs from the actual details, we reserve the right to cancel the booking without liability, regardless of the nature of the discrepancy (e.g., net weight, type, etc.), or if booking confirmation or shipping documents have already been issued and goods received.',
p3:'If you wish to make any changes or modifications to the shipment booking or the shipment itself, you must adhere to the following:',
l1:'The request for such changes must be submitted to us before the final deadline for shipment delivery.',
l2:'The requested changes must be practically feasible.',
l3:'Approval must be granted by us.',
p32:'Any approved modification request will be subject to repricing, and applicable rates will be applied accordingly. Any request to modify or change the shipment will be considered acceptance of any additional fees.',
p4:'In the event of a dispute regarding any matter related to the tasks of Across MENA, limited to its role as an international shipping agent, claims must be submitted online on the electronic platform www.acrossmena.com within ten days from the occurrence of the disputed issue.In all cases, the courts of Latakia, Syria, shall have jurisdiction over any legal dispute arising from the companys mentioned activities.It is also worth noting that any claims regarding excess fees or refunds must be submitted in writing to Across MENA within 90 days of shipment acceptance',
p5:'Across MENA provides door-to-door or port-to-port delivery to all major destinations in the Middle East, North Africa, Asia, Europe, the Americas, and Australia-excluding Israel. Future notifications will detail countries with which Syrian laws may prohibit commercial dealings.',
p6:'Charges are assessed based on various criteria, including, for example, route length (whether sea, air, or land), container shipping fees on commercial vessels linked to fluctuating shipping rate indicators, and variable air freight fees. Any sudden increases in these fees, inspection costs at transit ports, unexpected changes in sea, land, or air routes due to acts of war, piracy, natural disasters, or similar events will be added to the agreed fee, with the shipper responsible for these additional charges. Shippers will be informed of any extra fees applied to the agreed-upon rate.',
p7:'Local and foreign authorities reserve the right to inspect any shipment as deemed necessary. Shipments may be subject to repeated inspections at any commercial port in any country. As previously stated, all resulting fees and expenses are borne by the shipper, and Across MENA is not obligated to reimburse any amount under any label.',
p8:'As an agent only, Across MENA’s role is limited to mediating between carriers and shippers to arrange bookings on various shipping means and to clear goods. It does not participate in the actual transportation process. Therefore, Across MENA shall not be held liable under any circumstances for any total or partial damage to the goods, any loss or damage of any kind or cause to the goods, or for any failure or delay in loading or transporting the goods due to carrier negligence, port authority, or any third party.You acknowledge that the estimated delivery time provided is non-binding and does not impose any obligation on us. Additionally, you may not seek compensation for any type of physical or moral damage or other losses.In all cases, you guarantee that each shipment you book is lawful and that all information provided to us is accurate and true. You also commit to delivering goods for shipping through Across MENA in properly packed and suitable containers based on the nature of the goods. Furthermore, you agree to provide us with any additional information or documentation we may request at the time.',
p9:'Shipments will only be accepted upon prepayment of shipping fees as determined by Across MENA. Any unpaid fees may result in the shipment being held, stopped, or subjected to late fees and fines, affecting either the shipper or consignee.By accepting these terms, you acknowledge that Across MENA has the right to collect all fees and charges owed by any lawful means currently available or any future methods, including electronic payments or payments through affiliated companies or electronic applications. Across MENA is not obligated to announce',
p10:'All information provided or obtained is considered confidential and must not be disclosed without prior written consent from the other party. Both parties are responsible for ensuring that this confidentiality is maintained, except when required by official authorities or in court during legal disputes. This confidentiality commitment does not apply to information shared with third parties in the shipping process or public information, nor to information shared with any application or website affiliated with Across MENA.',
 },
    section:{
        headerCustomsDutiesCalculator:'Customs duties calculator',
        detailsCustomsDutiesCalculator:'The customs duty calculator tool allows you to estimate the total cost of importing goods according to the customs tariff and tax laws in the Syrian Arab Republic, and provides a wide range of detailed information about customs duties, including terms, conditions, and available indicative prices.',

        headerLandShipping:'Land Shipping',
        detailsLandShipping_1:'We provide a flexible and reliable service for international transportation to and from neighboring countries. We are distinguished by meticulous attention to detail and the ability to deal with various types and sizes of shipments in cooperation with the best truck drivers who are honest and have long knowledge and experience in the fastest and safest routes.',
        detailsLandShipping_2:'We are also subject to customs transit laws and controls and apply the highest performance standards to provide high-quality service based on customer needs and ensure safe and ideal transportation of their goods.',
        ourServicersTitle:'Our value added services include',
        service1:'Track the shipment from its point of departure until its arrival',
        service2:'Customs clearance of goods in all ports of the world',
        service3:'Providing the necessary materials for packaging according to the type of shipment',


        headerSeaShipping:'Sea Shipping',
        detailsSeaShipping_1:'We provide logistical solutions for sea freight for all types of goods with major shipping lines and at the best prices to reserve the appropriate container in an easy and simple way.',
        detailsSeaShipping_2:'Our sea freight management team adopts precision and technology in dealing with all types of sea shipments. We present regulations and standards in order to reduce the risks related to goods transported by sea, with the ability to track the goods and obtain reports on the actual status of the shipment until it reaches the port of unloading.',
        service1Sea:'Track the shipment from its point of departure until its arrival',
        service2Sea:'Customs clearance of goods in all ports of the world',
        service3Sea:'Providing the necessary materials for packaging according to the type of shipment',



        headerAirFright:'Air Freight',
        detailsAirFright_1:'We provide comprehensive coverage and innovative solutions for air freight that meet the needs of customers with the diversity and difference of their shipments in cooperation with a selected group of reliable airlines in the world and strategic partners with whom we have a close and long-term relationship.',
        detailsAirFright_2:'Our shipping experts provide you with classifications and restrictions on your goods, ensuring that the shipment is handled correctly and that you enjoy complete flexibility to send your goods wherever you are and whenever you want.',
        service1Air:'Handling dangerous shipments in line with safety standards',
        service2Air:'Customs clearance of goods at all airports in the world in the fastest ways',
        service3Air:'Review all information about prohibited and restricted goods by air',

        footerContactUs:'Contact Us',
        footerAddress:'Syria – Lattakia – Almoutanabi st',
        footerBuilding:'Elias Awad building',
        footerPhone:'Tel',
        footerMobile:'Mob',

    },
    actions:{
        feedback:'Feedback',
        titleSwa:'Your reservation has been sent successfully',
        textSwa:'It will be processed immediately',
        buttonSwa:'Agree',
		buttonFix:'OK',
        back:'Back',
        test:'Under Test',
        development:'Under Development',
        searchBtn:'Search',
        resetBtn:'Reset',
        buttonRequest:'Request',
        buttonclose:'Close',
        readMore:'Read More',
        submit:'Submit',
        readLess:'Read Less',
        buttonAgree:'Agree',
        buttonCancel:'Cancel',
        buttonBook:'Book now',
        showdetails:'view details',
        buttonNext:'Next',
        buttonSchedule:'Show Schedule',
        buttonConfirm:'Confirm ',
        confirmBtn:'Confirm',
		doneBtn:'Done',


    },
    tables:{
        table74Other:'Table of other items',
        table74Element:'Element',
        table74PercentAllow:'Permissible percentage limit (%) by weight',
        table74Silver:'Silver (Ag)',
        table74Zarnik:'Arsenic (As)',
        table74Cadmium:'Cadmium (Cd)',
        table74OtherElemnets:'Other elements, each (*)',
        table74OtherAre:'Other items (*) For example, she Aluminium(Al) Beryllium (Be) Cobalt(Co) Iron(Fe) Manganese (Mn) And nickel(Ni) Silicon(Si)',

        table11Passage:'Percentage of passage through a sieve with mesh sizes',
        table11Ash:'Ash percentage(3)',
        table11Starch:'Starch percentage(2)',
        table11Cereals:'Cereals(1)',
        table11Micro:'500 micrometer (micron)',
        table11Micro2:'315 micrometer (micron)',
        table11Rye:'Wheat and rye',
        table11Barly:'Barley',
        table11Oats:'Oats',
        table11Corn:'Corn and sorghum grains',
        table11Rice:'Rice',
        table11Buck:'Buckwheat'

    }

};

export default translations;
