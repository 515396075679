import './index.css';

// import mainImage from '../../../../assets/images/test.webp';
import {MainContainer} from '../../../../components'
import { Hero } from '../../components';
import FormBooking from '../../components/FormBooking/FormBooking';
import TimelineBooking from '../../components/TimelineBooking/TimelineBooking';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeLine from '../../components/TimeLine/TimeLine';
import FormNoResults from '../../components/FormNoResults/FormNoResults';
import FormAskManager from '../AskManager/FormAskManager';
import { FcManager } from "react-icons/fc";
import { FcAssistant } from "react-icons/fc";
import { LuUsers } from "react-icons/lu";

import { FaArrowRightLong } from "react-icons/fa6";


const AddBook = () => {

  const {t,i18n} = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const title= t('title.titleMainPage');
  const subTitle =t('title.subtitlemain');


  const {
    bookingObject
} = useSelector((state) => state.moduleMain.homeSlice);



const navigate = useNavigate();

const handleBackClick = () => {
	const lang = i18n.language; // Get the current language from i18n
	const targetUrl = `/${lang}/results-book`;
	navigate(targetUrl);
};

  useEffect(()=>{

    if(Object.keys(bookingObject).length ===0){

      if(localStorage.getItem('language')){
        navigate(`/${localStorage.getItem('language')}`);
      }else{
        navigate(`/ar`);

      }
      // navigate(location.search);
     }

  },[bookingObject])
	const {
    portsObject,
} = useSelector((state) => state.moduleMain.homeSlice);

const handleSubmit = () => {

	setShowPopup(true);

}

useEffect(() => {
  if (showPopup) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }

  // Cleanup on component unmount
  return () => {
    document.body.classList.remove('no-scroll');
  };
}, [showPopup]);



const handleClose = () => {
	setShowPopup(false);
};

const closePopup = () => {
	setShowPopup(false); // Hide the popup when the close button is clicked or on submission
};
  return (
    <>
{/* {console.log(portsObject)} */}
      {/* <Hero title={title} subTitle={subTitle} /> */}

      <MainContainer>

        <section className='add-booking'>


<div className='same-column'>
{/* <div className='horison-card'> */}
{/* <TimeLine Pos={"25%"} variant={"vertical"} portTitleFrom={t('bookingTitles.placeholderPortFrom')} portTitleTo={t('bookingTitles.placeholderPortTo')} numberStations={bookingObject.number_of_station}  portsObject={portsObject}  cityFrom={portsObject.portFrom} cityTo={portsObject.portTo}  endDate={bookingObject.date} startDate={bookingObject.end_date}  /> */}

{/* <TimeLine Pos={"25%"} variant={"undefine"} portTitleFrom={t('bookingTitles.placeholderPortFrom')} portTitleTo={t('bookingTitles.placeholderPortTo')} numberStations={bookingObject.number_of_station}  portsObject={portsObject}  cityFrom={portsObject.portFrom} cityTo={portsObject.portTo}  endDate={bookingObject.date} startDate={bookingObject.end_date}  />
</div> */}

          {/* Forms  */}
          <div className='booking__left'>

            <FormBooking/>

          </div>
					</div>

          {/* TimeLine */}
          <div className='booking__right'>
					<div class="container">
<button
      className="back-button"
      style={{ direction: 'ltr' }}
      onClick={handleBackClick}
    >
      <span className="arrow">{'<'}</span>
      <span>{t('actions.back')}</span>
    </button>

    </div>
						<div className='req-manager'>
						<div className='manager-content'>
<p style={{color:'#fcc400', fontWeight:'bold',marginTop:'-4px'}} className='text-askManager'> {t('bookingTitles.reqManager')}</p>
{/* <FcManager style={{ width: '66px', height: '65px',position:'relative',left:'-80px' ,color:'red'}} /> */}

<LuUsers style={{ width: '60px', height: '55px',position:'relative' ,color:'0D3453'}} className='icon-circle' />
<p style={{color:'#727272',fontSize:'16px',lineHeight:'30px'}}> {t('bookingTitles.howContact')}</p>
<button className='manager-button'  onClick={handleSubmit}>  {t('bookingTitles.help')} </button>
</div>

{showPopup && (
	  <div className='popup-container'>
          <div className='popup-content'>
            {/* Form component */}

            <FormAskManager closePopup={closePopup} />

            {/* Button to close the popup */}
            {/* <button className='popup-close' onClick={closePopup}>Close</button> */}
          </div>
          {/* Optional overlay to handle clicking outside the popup */}
          <div className='popup-overlay' onClick={closePopup}></div>
        </div>
      )}
				</div>
            <TimelineBooking numberStation={bookingObject.number_of_station} containerType={bookingObject.container} TotalPickUp={bookingObject.pickup} endDate={bookingObject.end_date} startDate={bookingObject.date} numberDays={bookingObject.number_of_day}  />

          </div>

        </section>

      </MainContainer>





    </>
  )
}

export default AddBook
